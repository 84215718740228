import { FC, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import useLoading from 'modules/stores/loading/useLoading';
import {
    MobileFilterHeader as MobileFilterHeaderHomePage
} from 'components/advertisementsFilter/components/mobile/homePage/partials/filterHeader/FilterHeader';
import {
    FilterLoader
} from 'components/advertisementsFilter/components/common/loader/FilterLoader';
import { Loading } from 'components/advertisementsFilter/components/mobile/listing/partials/loading/Loading';
import {
    ExtendedMobileFilterDialog
} from 'components/advertisementsFilter/components/extendedFilter/mobile/ExtendedMobileFilterDialog';

const MobileFilterHeaderListing = dynamic(
    () => import('./listing/partials/filterHeader/FilterHeader')
        .then((component) => component.MobileFilterHeader),
    {
        ssr: false,
        loading: () => <FilterLoader isMobile/> }
);

export const MobileFilter: FC = () => {
    const pathname = usePathname();
    const { isLoading } = useLoading();
    const [isFilterOpened, setIsFilterOpened] = useState(false);

    const isHomePage = pathname === '/';

    const toggleFilter = useCallback((flag: boolean) => {
        setIsFilterOpened(flag);
    }, []);

    return <>
        {isHomePage
            ? <MobileFilterHeaderHomePage
                toggleFilter={toggleFilter}
            />
            : <MobileFilterHeaderListing
                toggleFilter={toggleFilter}
                isFilterOpened={isFilterOpened}
                toggleExtendedFilter={toggleFilter}
            />
        }

        {(!isHomePage && isLoading) && <Loading/>}

        {isFilterOpened &&
            <ExtendedMobileFilterDialog
                toggleExtendedFilter={toggleFilter}
                isExtendedFilterOpened={isFilterOpened}
                onCloseFilter={() => toggleFilter(false)}
            />
        }
    </>;
};