'use client';

import { FC, useState, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { Grid2, Box } from '@mui/material';
import useDialog from 'modules/dialog/useDialog';
import { Mobile } from 'modules/theme/components/responsive';
import { MobileFilter } from 'components/advertisementsFilter/components/mobile/MobileFilter';
import { DesktopFilter } from 'components/advertisementsFilter/components/desktop/DesktopFilter';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';
import { ParametersSetter } from 'components/advertisementsFilter/components/common/parametersSetter/ParametersSetter';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';

const ExtendedDesktopFilterDialog = dynamic(() => import('./extendedFilter/desktop/ExtendedDesktopFilterDialog')
    .then((component) => component.ExtendedDesktopFilterDialog), { ssr: false });

const ExtendedMobileFilterDialog = dynamic(() => import('./extendedFilter/mobile/ExtendedMobileFilterDialog')
    .then((component) => component.ExtendedMobileFilterDialog), { ssr: false });

interface IAdvertisementsSearchFilter {
    filterDataFromUrl: FilterDataFromUrlType;
    pagination: IAdvertisementListingFilterPagination;
    initialSearchFilterData: IInitialSearchFilterData;
}

export const AdvertisementsSearchFilter: FC<IAdvertisementsSearchFilter> = ({
    pagination,
    filterDataFromUrl,
    initialSearchFilterData,
}) => {
    const [isExtendedMobileFilterOpened, setIsExtendedMobileFilterOpened] = useState(false);

    const toggleExtendedMobileFilter = useCallback((flag: boolean) => {
        setIsExtendedMobileFilterOpened(flag);
    }, []);

    const {
        isOpen: isOpened,
        component: extendedDesktopFilterDialog,
        show: onExtendedFilterDialogOpen,
        hide: hideExtendedFilterDialog,
    } = useDialog({
        dialogContentProps: {
            sx: { padding: 0, borderRadius: '16px' }
        },
        paperProps: {
            sx: {
                minWidth: 657,
                overflowY: 'auto',
                borderRadius: '16px'
            }
        },
        wrapContentIntoBox: false,
        content: <ExtendedDesktopFilterDialog
            onCloseFilter={() => hideExtendedFilterDialog()}
        />
    });

    return <>
        <ParametersSetter
            pagination={pagination}
            filterDataFromUrl={filterDataFromUrl}
            initialSearchFilterData={initialSearchFilterData}
        />

        <Mobile width='100%'>
            <Grid2 size={{xs: 12}}>
                <MobileFilter/>
            </Grid2>
        </Mobile>

        <Box width='100%' maxWidth={778} display={{xs: 'none', md: 'block'}}>
            <DesktopFilter
                isExtendedDialogOpened={isOpened}
                onExtendedDialogOpen={onExtendedFilterDialogOpen}
            />
        </Box>

        {extendedDesktopFilterDialog}

        {isExtendedMobileFilterOpened &&
            <ExtendedMobileFilterDialog
                toggleExtendedFilter={toggleExtendedMobileFilter}
                isExtendedFilterOpened={isExtendedMobileFilterOpened}
                onCloseFilter={() => toggleExtendedMobileFilter(
                    !isExtendedMobileFilterOpened
                )}
            />
        }
    </>;
};