import { FC } from 'react';
import { Box } from '@mui/material';
import { EInputs } from 'components/advertisementsFilter/modules/common/enums/EInputs';
import { Popover } from 'components/advertisementsFilter/modules/common/components/popover/Popover';
import { ILocation as ILocationOption } from 'modules/api/clients/location/interfaces/location/ILocation';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { LocationInput } from 'components/advertisementsFilter/modules/location/components/common/input/LocationInput';
import { CompleteBox } from 'components/advertisementsFilter/modules/location/components/common/completeBox/CompleteBox';
import { usePreviouslySearchedLocations } from 'components/advertisementsFilter/modules/location/hooks/usePreviouslySearchedLocations';
import { Autocomplete } from 'components/advertisementsFilter/modules/location/components/common/autocompletes/autocomplete/Autocomplete';
import { SelectedLocations } from 'components/advertisementsFilter/modules/location/components/common/selectedLocations/SelectedLocations';
import { PreviouslySearched } from 'components/advertisementsFilter/modules/location/components/common/autocompletes/previouslySearched/PreviouslySearched';
interface ILocation {
    isInFocus: boolean;
    isHovered: boolean;
    isPopoverOpen: boolean;
    onClosePopover: () => void;
    popoverAnchorEl: HTMLElement;
    onSetInputInFocus: (value: EInputs) => void;
    onChangeHoverState: (flag: EInputs) => void;
    onOpenPopover: (target: HTMLElement) => void;
    onChangeLocation: (location: ILocationOption) => void;
}

export const Location: FC<ILocation> = ({
    isInFocus,
    isHovered,
    onOpenPopover,
    isPopoverOpen,
    onClosePopover,
    popoverAnchorEl,
    onChangeLocation,
    onSetInputInFocus,
    onChangeHoverState,
}) => {
    const { options: previouslySearchedOptions } =
        usePreviouslySearchedLocations();

    const locationQuery = useLocationStore((store) => store.locationQuery);
    const changeLocationQuery = useLocationStore((store) => store.changeLocationQuery);
    const autocompleteOptions = useLocationStore((store) => store.autocompleteOptions);
    const setSelectedLocations = useLocationStore((store) => store.setSelectedLocations);
    const selectedLocationNames = useLocationStore((store) => store.selectedLocationNames);
    const locationOptionsInitial = useLocationStore((store) => store.locationOptionsInitial);

    const showSuggestions = locationQuery && locationQuery.length >= 2 && autocompleteOptions.length;
    const showLastSearched = !!previouslySearchedOptions.length && (!locationQuery || locationQuery.length === 1);
    const showLocationOptions = !previouslySearchedOptions.length && (!locationQuery || locationQuery.length === 1);
    const popoverNeedToBeHidden = locationQuery.length &&
        locationQuery.length >= 2 &&
        !autocompleteOptions.length &&
        !selectedLocationNames.length;
    const areSelectedLocations = !!selectedLocationNames.length;

    const suggestionsComponent = (
        <Autocomplete
            withXPadding
            title='Vyberte lokalitu:'
            options={autocompleteOptions}
            onChangeLocation={(value) => {
                onChangeLocation(value);
            }}
        />
    );

    const lastSearchedComponent = (
        <PreviouslySearched
            withXPadding
            title='Naposledy ste hľadali:'
            options={previouslySearchedOptions}
            onChangeLocation={(values) => {
                setSelectedLocations(values);
            }}
        />
    );

    const completeBoxComponent = (
        <CompleteBox
            options={locationOptionsInitial}
            onChangeLocation={(value) => {
                onChangeLocation(value);
            }}
        />
    );

    const component = showSuggestions
        ? suggestionsComponent
        : showLastSearched
            ? lastSearchedComponent
            : showLocationOptions
                ? completeBoxComponent
                : completeBoxComponent;

    return (
        <>
            <LocationInput
                label='Lokalita'
                inputWidth={224}
                isInFocus={isInFocus}
                isHovered={isHovered}
                value={isInFocus
                    ? locationQuery
                    : selectedLocationNames
                }
                onClick={onOpenPopover}
                placeholder="Mesto, obec ..."
                onChange={changeLocationQuery}
                onSetInputInFocus={onSetInputInFocus}
                onChangeHoverState={onChangeHoverState}
            />

            <Popover
                isOpen={isPopoverOpen && !popoverNeedToBeHidden}
                onClose={() => {
                    onClosePopover();
                    onSetInputInFocus(null);
                }}
                anchorEl={popoverAnchorEl}
                offsetOptions={{
                    x: 88,
                    y: 23,
                }}
            >
                <Box
                    width={416}
                    maxHeight={608}
                    pt={{ xs: 2, md: 3 }}
                    pb={{ xs: 0, md: 3 }}
                    sx={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    {areSelectedLocations &&
                        <Box mx={3} mb={{md: 1}}>
                            <SelectedLocations
                                locationQuery={locationQuery}
                                previouslySearched={previouslySearchedOptions}
                            />
                        </Box>
                    }

                    {component}
                </Box>
            </Popover>
        </>
    );
};
