import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import {
    SelectLocationsHint
} from 'components/advertisementsFilter/modules/location/components/common/completeBox/partials/selectHint/SelectLocationsHint';

interface ICompleteBox {
    options: ILocation[];
    onChangeLocation: (location: ILocation) => void;
}

export const CompleteBox: FC<ICompleteBox> = () => {
    const selectedLocations = useLocationStore((store) => store.selectedLocations);
    const showHint = selectedLocations.length <= 1;

    return <Box
        mx={{ xs: 1 }}
    >
        <Box
            py={{ xs: 1 }}
            pt={{ xs: 1 }}
            pb={{ xs: !showHint && 1.5 }}
        >
            <Text
                semibold
                variant='label1'
                color={(palette) => palette.labelSecondary}
            >
                Zadajte mesto, obec, okres ...
            </Text>
        </Box>

        {showHint &&
            <Box my={{xs: 1, md: 1.5}}>
                <SelectLocationsHint/>
            </Box>
        }
    </Box>;
};